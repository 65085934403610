<template>
  <div class="card card-custom card-transparent" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <div v-if="submitStatus === 'ERROR'">
      <b-alert show variant="danger" :style="$t('clan')=='ar'?'text-align:right':''"
        ><i class="fas fa-exclamation-circle text-white mr-3" ></i>

        {{$t('Alert')}}</b-alert
      >
    </div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">{{$t('New Driver')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Create New Driver')}}</div>
                </div>
              </div>
            </div>

            <div
              class="wizard-step"
              data-wizard-type="step"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">{{$t('Account Information')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Set Email and Password')}}</div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">{{$t('System Configurations')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Set System Config')}}</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">4</div>
                <div class="wizard-label">
                  <div class="wizard-title">{{$t('Completed')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Review and Submit')}}</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type=""></div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      class="form-group"
                      :class="{
                        'form-group--error': $v.driver.full_name.$error,
                      }"
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Full Name')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="fname"
                        :placeholder="$t('Full Name')"
                        v-model="driver.full_name"
                        :class="{
                          'form-input--error': $v.driver.full_name.$error,
                        }"
                        :state="
                          $v.driver.full_name.$error === true ? false : null
                        "
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>

                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.driver.phone.$error }"
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('phone')}}</label>
                      <b-form-input
                        type="tel"
                        class="form-control form-control-solid form-control-lg"
                        name="phone"
                        :placeholder="$t('phone')"
                        onKeyPress="if(this.value.length==10) return false;"
                        pattern="/^-?\d+\.?\d*$"
                        v-model="driver.phone"
                        :class="{ 'form-input--error': $v.driver.phone.$error }"
                        :state="$v.driver.phone.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                      <div class="error" v-if="!$v.driver.phone.minLength" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t("feildPhone")}}
                        {{ $v.driver.phone.$params.minLength.min }} {{$t("num")}}.
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-6">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.driver.national.$error,
                          }"
                        >
                          <label :style="$t('clan')=='ar'?'float:right':''">{{$t('National ID')}}</label>
                          <b-form-input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="National ID"
                            :placeholder="$t('National ID')"
                            v-model="driver.national"
                            :class="{
                              'form-input--error': $v.driver.national.$error,
                            }"
                            :state="
                              $v.driver.national.$error === true ? false : null
                            "
                          />
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.driver.birth_date.$error,
                          }"
                        >
                          <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Birth Date')}}</label>
                          <b-form-input
                            type="date"
                            class="form-control form-control-solid form-control-lg"
                            name="state"
                            :placeholder="$t('Birth Date')"
                            v-model="driver.birth_date"
                            :class="{
                              'form-input--error': $v.driver.birth_date.$error,
                            }"
                            :state="
                              $v.driver.birth_date.$error === true
                                ? false
                                : null
                            "
                          />
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>

                      <div class="col-xl-12">
                        <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Vehicle Type')}}</label>

                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.driver.vehicle_type.$error,
                          }"
                          
                        >
                          <select
                            class="form-control form-control-lg form-control-solid"
                            v-model="driver.vehicle_type"
                            :class="{
                              'form-input--error': $v.driver.vehicle_type.$error,
                            }"
                            :state="
                              $v.driver.vehicle_type.$error === true
                                ? false
                                : null
                            "
                            
                          >
                            <template v-for="type in types">
                              <option
                                :key="type"
                                :value="type"
                                :selected="
                                  driver.vehicle_type == type ? true : false
                                "
                              >
                                {{ $t(type) }}
                              </option>
                            </template>
                            <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                          </select>
                          
                        </div>
                      </div>

                       <!-- <div class="col-xl-6">
                        <label :style="$t('clan')=='ar'?'float:right':''">{{$t('SelectTarrif')}}</label>

                        <div
                          class="form-group"
                          
                          
                        >
                          <select
                            class="form-control form-control-lg form-control-solid"
                            v-model="driver.selected_tarrif"
                           
                            
                          >
                            <template v-for="tarrif in tarrifs">
                              <option
                                :key="tarrif.id"
                                :value="tarrif.id"
                                :selected="
                                  tarrif.id == driver.selected_tarrif ? true : false
                                "
                              >
                                {{tarrif.tarrif_name}}
                              </option>
                            </template>
                            <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                          </select>
                          
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->
                    <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                  <div class="row">
                    <div class="col-xl-6">
                    <div
                      class="form-group"
                      
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Email')}}</label>
                      <b-form-input
                        type="email"
                        class="form-control form-control-solid form-control-lg"
                        name="email"
                        placeholder="email"
                        v-model="driver.email"
                       
                      />
                      
                    </div>
                    </div>

                    <div class="col-xl-6">
                    <div
                      class="form-group"
                      
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Password')}}</label>
                      <b-form-input
                        type="password"
                        class="form-control form-control-solid form-control-lg"
                        name="password"
                        placeholder="email"
                        v-model="driver.password"
                       
                      />
                      
                    </div>
                  </div>



            <div
                      class="form-group col-xl-12"
                      
                      
                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('WorkingDay')}}</label>
                      <div
                          
                        >
                          <vue-select
                            name="drivers"
                            label="full_name"
                            :key="'drivers-field'"
                            :options="createData"
                            :closeOnSelect="false"
                            multiple
                            @input="updateWorkingDays"
                            :class="{
                              'form-select--error': $v.driver.working_days.$error,
                            }"
                            

                          ></vue-select>
                          
                        </div>
                    </div>


                    
                    </div>

                   <div
                      class="form-group row"

                    >
                    <div class="col-sm-12">
                        <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('WorkingHours')}}</label>
                    </div>
                      
                      <div
                      class="col-sm-6 d-flex mt-5"
                        
                        >
                        
                          <datetime 
                          :class="{
                              'form-select--error': $v.driver.working_hours_from.$error,
                            }"
                            v-model="driver.working_hours_from"  
                             type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">

                          </datetime>
                        
                        </div>
                        <div
                      class="col-sm-6 d-flex mt-5"

                        >
                        <span class="mr-4 mt-3">{{$t('To')}}:</span>
                          <datetime  
                          :class="{
                              'form-select--error': $v.driver.working_hours_to.$error,
                            }"
                            v-model="driver.working_hours_to"  type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">

                          </datetime>
                         
                        </div>
                    </div>

                    <div
                      class="form-group"
                      
                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('workinghourcharges')}} ( {{driver.working_hour_charges_mode}} )</label>
                      <span class="switch switch-icon switch-sm mb-2" style="float:right" >
                        <label> 
                        <input
                            type="checkbox"
                            name="select"
                            v-model="WorkinghourTypeToggled"
                            @click="setWorkingToggle"
                            
                        />
                        <span></span>
                        </label>
                    </span>

                        <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        :placeholder="$t('Value')"
                        :class="{ 'form-input--error': $v.driver.working_hour_charges_value.$error }"
                        :state="$v.driver.working_hour_charges_value.$error === true ? false : null"
                        :style="$t('clan')=='ar'?'text-align:right':''"
                        v-model="driver.working_hour_charges_value"

                      />

                     
                    </div>


                    <div
                      class="form-group"

                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('Nonworkinghourcharges')}} ( {{driver.non_working_hour_charges_mode}} )</label>
                      <span class="switch switch-icon switch-sm mb-2" style="float:right" >
                        <label> 
                        <input
                            type="checkbox"
                            name="select"
                            v-model="NoNWorkinghourTypeToggled"
                            @click="setNoNWorkingToggle"
                        />
                        <span></span>
                        </label>
                    </span>

                        <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        :placeholder="$t('Value')"
                        :class="{ 'form-input--error': $v.driver.non_working_hour_charges_value.$error }"
                        :state="$v.driver.non_working_hour_charges_value.$error === true ? false : null"
                        :style="$t('clan')=='ar'?'text-align:right':''"
                        v-model="driver.non_working_hour_charges_value"

                      />

                    </div>
                  

                    
                  </div>
                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.driver.tokan.$error }"
                    >
                      <label>TookanID</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="TookanID"
                        placeholder="TookanID"
                        v-model="driver.tokan"
                        :class="{ 'form-input--error': $v.driver.tokan.$error }"
                        :state="$v.driver.tokan.$error === true ? false : null"
                      />
                     <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>

                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.driver.mac.$error }"
                    >
                      <label>McDonad's ID</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="McDonad's ID"
                        placeholder="McDonad's ID"
                        v-model="driver.mac"
                        :class="{ 'form-input--error': $v.driver.mac.$error }"
                        :state="$v.driver.mac.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>

                    <div
                      class="form-group"
                      :class="{
                        'form-group--error': $v.driver.quickbooks.$error,
                      }"
                    >
                      <label>Quickbooks ID</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="QbooksID"
                        placeholder="QbooksID"
                        v-model="driver.quickbooks"
                        :class="{
                          'form-input--error': $v.driver.quickbooks.$error,
                        }"
                        :state="
                          $v.driver.quickbooks.$error === true ? false : null
                        "
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content" :style="$t('clan')=='ar'?'text-align:right':''">
                    <div class="mb-10 font-weight-bold text-dark">
                      {{$t('rev')}}
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">{{$t('info')}}:</div>
                      <div class="line-height-md">
                        {{ driver.full_name }}
                        <br />
                        {{$t('phone')}}: {{ driver.phone }} <br />
                        {{$t('National ID')}}: {{ driver.national }} <br />
                        {{$t('Birth Date')}}: {{ driver.birth_date }} <br />
                        <!-- Attachment: Attachment <br /> -->
                      </div>
                    </div>
                    <div class="mb-5 pb-5">
                      <div class="font-weight-bold mb-3">{{$t('info')}}:</div>
                      <div class="mb-5">
                        TookanID: {{ driver.tokam }}
                        <br />
                        McDonad's ID: {{ driver.mac }} <br />
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        {{$t('Previous')}}
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        {{$t('Submit')}}
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        {{$t('Next Step')}}
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "CreateDriver",

  data() {
    return {
      driver: {
        full_name: '',
        name:'',
        phone: null,
        national: null,
        birth_date: null,
        tokan: null,
        mac: null,
        quickbooks: null,
        vehicle_type:'',
        email:'',
        password:'',
        //selected_tarrif:'',
        working_days:'',
        working_hours_from:'',
        working_hours_to:'',
        working_hour_charges_mode:'Fixed',
        working_hour_charges_value:'',
        non_working_hour_charges_mode:'Fixed',
        non_working_hour_charges_value:'',
      },
      createData: ['Sunday','Monday','Tuesday','Wednesday','Thursday ','Friday','Saturday'],
      types:['car','scooter','bicycle'],
      modes:['fixed','percentage'],
      WorkinghourTypeToggled:false,
      WorkinghourTypeToggledValue:'Fixed',
      NoNWorkinghourTypeToggled:false,
      NoNWorkinghourTypeToggledValue:'Fixed',
      tarrifs:[],
      submitStatus: null,
      Schedule:{
          workingdays:{working:[]},
      },
    };
  },
  validations: {
    driver: {
      full_name: {
        required,
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      national: {
        required,
      },
      birth_date: {
        required,
      },
      tokan: {
        required,
      },
      mac: {
        required,
      },
      quickbooks: {
        required,
      },
      vehicle_type:{
        required,
      },
      working_days:{
        required
      },
      working_hours_from:{
        required
      },
      working_hours_to:{
        required
      },
      working_hour_charges_value:{
        required
      },
      non_working_hour_charges_value:{
        required
      },
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Drivers", route: "/admin/drivers" },
      { title: "New Driver" },
    ]);


      var tarrifs = await this.$store.dispatch('clientTarrif/loadAll')
    this.tarrifs = tarrifs.data

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {});

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    updateWorkingDays(value){
        this.Schedule.workingdays.working = value;
        this.driver.working_days = JSON.stringify(this.Schedule.workingdays)
      console.log(this.driver.working_days)
        //console.log(this.Schedule.workingdays)
    },
    setWorkingToggle(){
          if(this.WorkinghourTypeToggled === true){
        this.WorkinghourTypeToggled = false;
        //this.WorkinghourTypeToggledValue='Fixed'
        this.driver.working_hour_charges_mode='fixed'
        
        

      }
      else if(this.WorkinghourTypeToggled === false){
        this.WorkinghourTypeToggled = true
        //this.WorkinghourTypeToggledValue='Percentage'
        this.driver.working_hour_charges_mode='percentage'
       
        
      }
      
      },
      setNoNWorkingToggle(){
          if(this.NoNWorkinghourTypeToggled === true){
        this.NoNWorkinghourTypeToggled = false;
        //this.NoNWorkinghourTypeToggledValue='Fixed'
        this.driver.non_working_hour_charges_mode='fixed'
        
        

      }
      else if(this.NoNWorkinghourTypeToggled === false){
        this.NoNWorkinghourTypeToggled = true
        //this.NoNWorkinghourTypeToggledValue='Percentage'
        this.driver.non_working_hour_charges_mode='percentage'
       
        
      }
      
      },
    async submit() {
      this.driver.name = this.driver.full_name
       //console.log(this.driver)
      this.$v.$touch();
      if (this.$v.$invalid) {
        if (this.$v.$invalid) {
        try{
          
           //console.log(this.driver)
         await  this.$store.dispatch("drivers/create", this.driver);
          
       
        
      }catch(error){
        console.log(error.response.status)
        console.log(error.response)
        var errmessage = error.response.data.message
        var mes =''
        if(error.response.data.errors.fees_mode !==undefined){
          var fees_mode = error.response.data.errors.fees_mode[1]
          mes = mes + fees_mode
        }
        if(error.response.data.errors.fees_value !==undefined){
          var fees_value = error.response.data.errors.fees_value
          mes = mes + fees_value
        }
        if(error.response.data.errors.full_name !==undefined){
          var full_name = error.response.data.errors.full_name[1]
          mes = mes + full_name
        }
        if(error.response.data.errors.mac !==undefined){
          var mac = error.response.data.errors.mac[1]
          mes = mes + mac
        }
        if(error.response.data.errors.national !==undefined){
          var national = error.response.data.errors.national[1]
          mes = mes + national
        }
        if(error.response.data.errors.phone !==undefined){
          var phone = error.response.data.errors.phone[1]
          mes = mes + phone
        }
        if(error.response.data.errors.tokan !==undefined){
          var tokan = error.response.data.errors.tokan[1]
          mes = mes + tokan
        }
        if(error.response.data.errors.vehicle_type !==undefined){
          var vehicle_type = error.response.data.errors.vehicle_type[1]
          mes = mes + vehicle_type
        }
        
       
        
        
        
        Swal.fire({
          title: errmessage,
          text: mes ,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }}
        this.submitStatus = "ERROR";
      } else {
        await this.$store.dispatch("drivers/create", this.driver);

        Swal.fire({
          title: "",
          text: "The driver has been created successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$router.push({ name: "Drivers" });
        this.$store.dispatch("drivers/loadAll");

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}

.error {
  color: rgb(235, 128, 41);
}
</style>